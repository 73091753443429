<template>
  <div>

    <div class="card mb-5 mb-xl-10" id="kt_profile_details_view">

      <div class="card-header cursor-pointer">

        <div class="card-title m-0">
          <h3 class="fw-bolder m-0">Consumer Order Edit</h3>
        </div>

        <router-link class="btn btn-secondary" :to="'/orders/consumer/recorddispatches/'+route.params.id"><i class="fs-1-3 la la-shipping-fast"></i>Record Dispatches</router-link>

      </div>
      <div class="card-body p-7">
        <div class="row mb-3" v-show="success">
          <div class="col-12">
            <div class="alert alert-success" role="alert">
              Successfully changed consumer order!
            </div>
          </div>
        </div>

        <div class="row mb-3">
          <div class="col-12 mb-3 col-lg-6 mb-lg-0">
            <label class="col-form-label required fw-bold fs-6">Consumer</label>
            <input type="text" class="form-control" :value="store.getters.currentConsumerOrder.consumer ? store.getters.currentConsumerOrder.consumer.firstName+' '+store.getters.currentConsumerOrder.consumer.lastName : ''" :disabled="true">
          </div>
          <div class="col-12 col-lg-6">
            <label class="col-form-label required fw-bold fs-6">Order Date</label>
            <input type="date" class="form-control" :class="{'is-invalid' : errors.value && errors.value.orderDate}" v-model="store.getters.currentConsumerOrder.orderDate" :disabled="true">
            <span class="text-danger" v-if="errors.value && errors.value.orderDate">{{errors.value && errors.value.orderDate[0]}}</span>
          </div>
        </div>

        <div class="row mb-3">
          <div class="col-12 mb-3 col-lg-6 mb-lg-0">
            <label class="col-form-label required fw-bold fs-6">Reference</label>
            <input type="text" class="form-control" :class="{'is-invalid' : errors.value && errors.value.reference}" v-model="store.getters.currentConsumerOrder.reference" disabled>
            <span class="text-danger" v-if="errors.value && errors.value.reference">{{errors.value && errors.value.reference[0]}}</span>
          </div>
          <div class="col-12 mb-3 col-lg-6 mb-lg-0">
            <label class="col-form-label required fw-bold fs-6">PO Currency</label>
            <select class="form-select" :class="{'is-invalid' : errors.value && errors.value.currency_id}" v-model="store.getters.currentConsumerOrder.currency_id" :disabled="true">
              <option value="null" selected disabled>Select Currency</option>
              <option :value="item.id" v-for="(item,key) in store.getters.myCurrencies" :key="key">{{item.name}}</option>
            </select>
            <span class="text-danger" v-if="errors.value && errors.value.currency_id">{{errors.value && errors.value.currency_id[0]}}</span>
          </div>

        </div>

        <div class="row mb-3">
          <div class="col-12 mb-3 col-lg-6 mb-lg-0">
            <label class="col-form-label required fw-bold fs-6">Order Status</label>
            <select class="form-select" :class="{'is-invalid' : errors.value && errors.value.order_status_id}" v-model="store.getters.currentConsumerOrder.order_status_id">
              <option value="null" selected disabled>Select Status</option>
              <option :value="item.id" v-for="(item,key) in store.getters.myOrderStatuses" :key="key">{{item.name}}</option>
            </select>
            <span class="text-danger" v-if="errors.value && errors.value.order_status_id">{{errors.value && errors.value.order_status_id[0]}}</span>
          </div>
        </div>

      </div>

      <div class="card-footer cursor-pointer">
        <!--begin::Card title-->
        <div class="text-end m-0">
          <button
            type="submit"
            ref="submitButton"
            class="btn btn-lg btn-primary fw-bolder my-2"
            @click="submitForm"
          >
            <span class="indicator-label">
              Save
            </span>

            <span class="indicator-progress">
              Please wait...
              <span
                class="spinner-border spinner-border-sm align-middle ms-2"
              ></span>
            </span>
          </button>
        </div>
        <!--end::Card title-->
      </div>

    </div>

    <div class="card mb-5 mb-xl-10" id="kt_profile_details_view">
      <!--begin::Card header-->
      <div class="card-header cursor-pointer">
        <!--begin::Card title-->
        <div class="card-title m-0">
          <h3 class="fw-bolder m-0">Shipping Info</h3>
        </div>
        <!--end::Card title-->
      </div>
      <!--begin::Card header-->

      <!--begin::Card body-->
      <div class="card-body p-9">
        <div class="row mb-3">
          <div class="col-12 col-lg-6 mb-3 mb-lg-0">
            <label class="col-form-label required fw-bold fs-6">First Name</label>
            <input type="text" class="form-control" :value="store.getters.orderShippingInfo ? store.getters.orderShippingInfo.firstName : ''" placeholder="Enter first name please" :disabled="true">
          </div>
          <div class="col-12 col-lg-6 mb-3 mb-lg-0">
            <label class="col-form-label required fw-bold fs-6">Last Name</label>
            <input type="text" class="form-control" :value="store.getters.orderShippingInfo ? store.getters.orderShippingInfo.lastName : ''" placeholder="Enter last name please" :disabled="true">
          </div>
        </div>

        <div class="row mb-3">
          <div class="col-12">
            <label class="col-form-label required fw-bold fs-6">Birthday</label>
            <input type="date" class="form-control" :value="store.getters.orderShippingInfo ? store.getters.orderShippingInfo.birthday : ''" placeholder="Enter birthday please" :disabled="true">
          </div>
        </div>

        <div class="row mb-3">
          <div class="col-12">
            <label class="col-form-label required fw-bold fs-6">Address Line 1</label>
            <input type="text" class="form-control" :value="store.getters.orderShippingInfo ? store.getters.orderShippingInfo.address1 : ''" placeholder="Enter address please" :disabled="true">
          </div>
        </div>
        <div class="row mb-3">
          <div class="col-12">
            <label class="col-form-label fw-bold fs-6">Address Line 2</label>
            <input type="text" class="form-control" :value="store.getters.orderShippingInfo ? store.getters.orderShippingInfo.address2 : ''" placeholder="Enter address please" :disabled="true">
          </div>
        </div>
        <div class="row mb-3">
          <div class="col-12 col-lg-6 mb-3 mb-lg-0">
            <label class="col-form-label required fw-bold fs-6">Town / City</label>
            <input type="text" class="form-control" :value="store.getters.orderShippingInfo ? store.getters.orderShippingInfo.city : ''" placeholder="Enter town / city please" :disabled="true">
          </div>
          <div class="col-12 col-lg-6 mb-3 mb-lg-0">
            <label class="col-form-label fw-bold fs-6">County</label>
            <input type="text" class="form-control" :value="store.getters.orderShippingInfo ? store.getters.orderShippingInfo.county : ''" placeholder="Enter county please" :disabled="true">
          </div>
        </div>

        <div class="row mb-3">
          <div class="col-12 col-lg-6 mb-3 mb-lg-0">
            <label class="col-form-label required fw-bold fs-6">Country</label>
            <input type="text" class="form-control" :value="store.getters.orderShippingInfo ? store.getters.orderShippingInfo.country : ''" placeholder="Enter country please" :disabled="true">
          </div>
          <div class="col-12 col-lg-6 mb-3 mb-lg-0">
            <label class="col-form-label required fw-bold fs-6">Postcode</label>
            <input type="text" class="form-control" :value="store.getters.orderShippingInfo ? store.getters.orderShippingInfo.postCode : ''" placeholder="Enter postode please" :disabled="true">
          </div>
        </div>

        <div class="row mb-3">
          <div class="col-12">
            <label class="col-form-label required fw-bold fs-6">Email</label>
            <input type="email" class="form-control" :value="store.getters.orderShippingInfo ? store.getters.orderShippingInfo.email : ''" placeholder="Enter email please" :disabled="true">
          </div>
        </div>

        <div class="row mb-3">
          <div class="col-12 col-lg-6 mb-3 mb-lg-0">
            <label class="col-form-label required fw-bold fs-6">Mobile</label>
            <input type="text" class="form-control" :value="store.getters.orderShippingInfo ? store.getters.orderShippingInfo.mobile : ''" placeholder="Enter mobile please" :disabled="true">
          </div>
          <div class="col-12 col-lg-6 mb-3 mb-lg-0">
            <label class="col-form-label fw-bold fs-6">Tel</label>
            <input type="text" class="form-control" :value="store.getters.orderShippingInfo ? store.getters.orderShippingInfo.telephone : ''" placeholder="Enter telephone please" :disabled="true">
          </div>
        </div>
      </div>
      <!--end::Card body-->

    </div>


    <div class="card mb-5 mb-xl-10" id="kt_profile_details_view1">

      <div class="card-header cursor-pointer">

        <div class="card-title m-0">
          <h3 class="fw-bolder m-0">Products</h3>
        </div>

      </div>
      <div class="card-body p-7">

        <Table :columns="columns" ref="table" :action="productActions.SEARCH_CONSUMER_ORDER_PRODUCTS" :id="Number(route.params.id)" :searchEnabled="false" :showPerPageEnabled="false">


          <!-- <template v-slot:extra="slotProps">

            <div class="mb-5">

                <SupplierOrderProductAdd :id="route.params.id" @input="slotProps.search()"/>

            </div>

          </template> -->

          <template v-slot:costPerUnit="slotProps">
            <div>{{ numeral(slotProps.row.costPerUnit).format('0,0.00000') }}</div>
          </template>

          <template v-slot:total="slotProps">
            <div>{{ numeral(slotProps.row.total).format('0,0.00000') }}</div>
          </template>

          <template v-slot:transportPerUnit="slotProps">
            <div>{{ numeral(slotProps.row.transportPerUnit).format('0,0.00000') }}</div>
          </template>



          <template v-slot:totalTrans="slotProps">
            <div>{{numeral(Number(slotProps.row.units * slotProps.row.transportPerUnit)).format('0,0.00000')}}</div>
          </template>

        </Table>

      </div>


    </div>
  </div>
</template>
<script lang="ts">
import { reactive, ref, onMounted, computed } from 'vue'
import { useStore } from "vuex";
import { Actions } from '@/store/enums/ConsumerOrderEnums.ts';
import { Actions as ConsumerOrderProductActions } from '@/store/enums/ConsumerOrderProductEnums.ts';
import { Actions as OrderStatusActions } from '@/store/enums/OrderStatusEnums.ts';
import { Actions as CurrencyActions } from '@/store/enums/CurrencyEnums.ts';
import { Actions as ConsumerOrderActions } from '@/store/enums/ConsumerOrderEnums.ts';
import { useRoute } from "vue-router";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import Table from '@/components/custom/Table.vue';
import numeral from 'numeral'
import { Actions as StoreActions } from "@/store/enums/StoreEnums.ts";
export default {
  name : 'ConsumerOrderEdit',
  components : {
    Table,
  },
  setup(){
    const store = useStore();
    // const router = useRouter();
    const route = useRoute();

    const success = ref(false);

    const productActions = computed(() => {
      return ConsumerOrderProductActions;
    })

    store.dispatch(ConsumerOrderActions.GET_ORDER_SHIPPING_INFO,{id : route.params.id})

    store.dispatch(StoreActions.ADD_BODY_CLASSNAME,'page-loading')

    onMounted(() => {
      setCurrentPageBreadcrumbs("Consumer Orders",[
        {title : "Consumer Orders",to : "/orders/consumer/list"},
        {title : "Consumer Order  Edit",to : "/orders/consumer/edit/"+route.params.id}
      ]);

      store.dispatch(CurrencyActions.GET_CURRENCIES);
      store.dispatch(OrderStatusActions.GET_ORDER_STATUSES);

      store.dispatch(Actions.GET_CONSUMER_ORDER,{id : route.params.id}).then(() => {

        store.dispatch(StoreActions.REMOVE_BODY_CLASSNAME,'page-loading')

      });



    });

    const submitButton = ref<HTMLElement | null>(null);

    const errors = reactive({
      value : {}
    });


    const submitForm = () => {

      if(submitButton.value){
        submitButton.value.setAttribute("data-kt-indicator", "on");
      }

      store.dispatch(Actions.UPDATE_CONSUMER_ORDER,{id : store.getters.currentConsumerOrder.id, order_status_id : store.getters.currentConsumerOrder.order_status_id}).then((r) => {
        if(r.success){
          errors.value = [];
          success.value = true;
          window.scrollTo(0,0);
          submitButton.value?.removeAttribute("data-kt-indicator");
        }

      }).catch((response) => {
        setTimeout(function(){
          const input = document.getElementsByClassName('is-invalid')[0] as HTMLElement
          input.focus();
        },100)

        errors.value = response.data.errors;

        submitButton.value?.removeAttribute("data-kt-indicator");

      })
    }

    const columns = [
      {name : 'code', title : 'Product Code', sortable : true, sort : 'asc', searchable : true},
      {name : 'name', title : 'Product Name', sortable : true, sort : null, searchable : true},
      {name : 'units', title : 'Units', sortable : true, sort : null, searchable : true},
      {name : 'currency', title : 'Currency', sortable : true, sort : null, searchable : true},
      // {name : 'costPerUnit', title : 'Cost / Unit', sortable : true, sort : null, searchable : false},
      {name : 'total', title : 'Total', sortable : true, sort : null, searchable : true},
      // {name : 'transportPerUnit', title : 'Trans / Unit', sortable : true, sort : null, searchable : true},
      // {name : 'totalTrans', title : 'Total / Trans', sortable : false, sort : null, searchable : false},
      {name : 'status', title : 'Status', sortable : true, sort : null, searchable : true},
      // {name : 'actions', title : 'Edit / Delete',sortable : false, sort : null, searchable : false}
    ];


    return {
      submitButton,
      submitForm,
      errors,
      store,
      success,
      route,
      columns,
      productActions,
      numeral
    }
  }
}
</script>
